import { createAction } from "@reduxjs/toolkit";
import {
  FlybuysPreferencesAction,
  VerifyFlybuysLinkingRequest,
} from "./FlybuysSlice";
import { WithNav } from "../../utils/withNav";

export const linkFlybuysAction = createAction<WithNav>("flybuysLinking");
export const verifyFlybuysLinkingAction =
  createAction<WithNav<VerifyFlybuysLinkingRequest>>("flybuys/verify");
export const flybuysPreferencesAction =
  createAction<FlybuysPreferencesAction>("flybuys/skip");
