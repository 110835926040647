import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProfileTypeState {
  isB2BUser: boolean;
  isB2BUserSigningUp: boolean;
}

const initialState: ProfileTypeState = {
  isB2BUser: false,
  isB2BUserSigningUp: false,
};

export const profileTypeSlice = createSlice({
  name: "profileType",
  initialState,
  reducers: {
    setIsB2BUserFlag: (state, action: PayloadAction<boolean>) => {
      state.isB2BUser = action.payload;
    },
    setIsB2BUserSigningUpFlag: (state, action: PayloadAction<boolean>) => {
      state.isB2BUserSigningUp = action.payload;
    },
  },
});

export const { setIsB2BUserFlag, setIsB2BUserSigningUpFlag } =
  profileTypeSlice.actions;

export default profileTypeSlice.reducer;
